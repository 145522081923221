import './bootstrap'
import '../css/app.css'
import { createApp, h } from 'vue'
import VueKonva from 'vue-konva'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import mitt from 'mitt'
import { modal } from 'momentum-modal'
import { createPinia } from 'pinia'
import { createInertiaApp } from '@inertiajs/vue3'
import { draggable } from '@/Lib/directives/draggable'
import { tooltip } from '@/Lib/directives/tooltip'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import { Icons } from './Lib/icons'

library.add(Icons)

const appName =
    window.document.getElementsByTagName('title')[0]?.innerText || 'Punchlist'

createInertiaApp({
    title: (title) => `${appName} | ${title}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue')
        ),
    progress: {
        color: '#4338ca',
    },
    setup({ el, App, props, plugin }) {
        const pinia = createPinia()

        return createApp({ render: () => h(App, props) })
            .component('font-awesome-icon', FontAwesomeIcon)
            .mixin({
                methods: {
                    asset: window.Vapor.asset,
                },
            })
            .directive('draggable', {
                beforeMount(el, binding) {
                    draggable(el, binding)
                },
            })
            .directive('tooltip', {
                beforeMount(el, binding) {
                    tooltip(el, binding)
                },
            })
            .provide('bus', mitt())
            .use(pinia)
            .use(ZiggyVue, Ziggy)
            .use(modal, {
                resolve: (name) =>
                    resolvePageComponent(
                        `./Pages/${name}.vue`,
                        import.meta.glob('./Pages/**/*.vue')
                    ),
            })
            .use(plugin)
            .use(
                ['production'].includes(import.meta.env.VITE_APP_ENV)
                    ? heap
                    : () => {}
            )
            .use(
                ['production', 'staging'].includes(import.meta.env.VITE_APP_ENV)
                    ? bugsnagVue
                    : () => {}
            )
            .use(VueKonva)
            .mount(el)
    },
})
